<template>
  <div>
    <div class="flexrow tiecg">
      <div class="tilelft">
        <div style="margin-right: 10px">{{ form.proName }} </div>
        <div>
          <span style="font-size:14px;margin-right:10px">当前状态:</span>
          <el-tag size="small" :style="{ 'background-color': statusform.rgb, 'color': 'white' }">{{
            statusform.name }}</el-tag>
          <!-- 当前类型：{{ nowType }}，{{ statusform.number }} -->
        </div>
      </div>
      <div class="tieright">
        <el-button type="primary" @click="goback">返回</el-button>
      </div>
    </div>
    <div class="flexrow">
      <div class="leftcg">
        <div class="lefttie">
          <div class="ltleft">相关文件</div>
        </div>
        <div class="leftfile" v-if="materImgList.length == 0">暂未提交材料</div>
        <div class="leftfile" v-else>
          <div class="filelistcg" v-if="materImgList">
            <div class="flexrow cgfiles" v-for="(item, index) in materImgList" :key="index">
              <div class="cgfleft">
                <el-tooltip class="item" effect="light" :content="item.name" placement="bottom">
                  <div class="cgfleftto">{{ item.name }}</div>
                </el-tooltip>
              </div>
              <div class="cgfrig" v-if="item.url.endsWith('.doc') || item.url.endsWith('.docx')">
                <a :href="showFileSrc" target="_blank" class="ltright"
                  style="color: #509bfd; text-decoration: none">下载</a>
              </div>
              <div class="cgfrig" @click="viewFile(item.url)" v-else>查看</div>
            </div>
          </div>
        </div>
      </div>
      <div class="centercg">
        <div class="lefttie">
          <div class="ltleft">文件预览</div>
          <div class="ltright" v-if="showFileSrc">
            <a :href="showFileSrc" target="_blank" class="ltright">全屏预览</a>
          </div>
        </div>
        <div v-if="showFileSrc.length == 0" class="fileiframe">
          暂未文件预览
        </div>
        <div v-if="showFileSrc.length > 0" class="fileiframe">
          <div ref="docx_dom" class="iframe" v-if="showFileType == 1"></div>
          <iframe frameborder="0" class="iframe" v-else-if="showFileType == 2" :src="showFileSrc"></iframe>
        </div>
      </div>
      <div class="rightcg">
        <div class="lefttie" style="margin-bottom: 10px; border-bottom: none">
          <div class="ltleft">审核意见</div>
        </div>
        <!-- 管理员审核 -->
        <div v-if="isAdmin == 1">
          <el-timeline :reverse="reverse">
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :style="index == activities.length - 1
              ? 'color: #409EFF'
              : 'color: #999999'
              ">
              <div class="flex-row">
                <div class="checktitle" style="color: #999999">
                  {{ activity.type | shenchaType }}
                </div>
                <div class="shehe">审核人：{{ activity.uname }}</div>
              </div>
              <div class="kuang">
                <div class="cr-card">
                  <div class="checkcont">
                    {{ activity.content }}
                  </div>
                  <div class="chfiles">
                    <div class="cgfleft" v-for="(item, index) in activeFile(activity.type)" :key="index">
                      <el-tooltip class="item" effect="light" :content="item.name" placement="bottom">
                        <div class="cgfleftto" @click="zhanshi(item)">
                          {{ item.name }}
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="linetie">
                  <div class="lttime">
                    {{ activity.created_at | formatDate }}
                  </div>
                </div>
              </div>
            </el-timeline-item>

            <el-timeline-item color="#409EFF">
              <div class="checktitle" style="color: #409eff">
                {{ nowType | shenchaType(newType) }}
              </div>

              <div class="kuang">
                <div class="cr-card">
                  <div class="checkcont">
                    <el-input type="textarea" :rows="2" style="min-height: 100px" placeholder="请输入审查意见"
                      v-model="content">
                    </el-input>
                  </div>
                  <div class="flexrow filebtn">
                    <div class="ltmanger">
                      <div v-for="(item, index) in uploadedImages" :key="index" class="listrow">
                        <div style="width: 90%" class="listrownam">
                          {{ item.name }}
                        </div>
                        <div v-if="uploadedImages.length > 0">
                          <i @click="delfiles(index)" class="el-icon-close" style="color: #f56c6c; cursor: pointer"></i>
                        </div>
                      </div>
                    </div>
                    <div class="lttime">
                      <el-upload :data="{
                        token: $store.state.token,
                      }" :file-list="uploadedImages" :on-success="(response, file, fileList) =>
                        changeMaterImg(1, response, file, fileList)
                        " :show-file-list="false" action="api/public/bupload" class="upload-demo" accept=".png,.jpg">
                        <el-button size="mini" type="primary">上传
                        </el-button>
                      </el-upload>
                    </div>
                  </div>
                  <div class="flowrow">
                    <div class="flowleft">意见模板</div>
                    <div class="flowright">
                      <i class="el-icon-circle-plus-outline" @click="dialogVisible = true"></i>
                    </div>
                  </div>
                  <div class="checkcont moban">
                    <div v-for="(item, index) in mblist" :key="index">
                      <div class="mbline">
                        <div class="neirong">
                          <el-tooltip class="item" effect="light" :content="item.content" placement="bottom">
                            <div class="neirongto" @click="changeContent(item)">
                              {{ item.content }}
                            </div>
                          </el-tooltip>
                        </div>
                        <div class="delbtn" @click="delmblist">
                          <i class="el-icon-close" style="color: #f56c6c"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
          <div class="flexrow tgbtn">
            <el-button type="success" @click="checkpass" v-if="checkListstatus.includes(form.status)">{{ pass
              }}</el-button>
            <el-button type="success" disabled v-if="disagreeListstatus.includes(form.status)">{{ pass }}</el-button>
            <el-button type="danger" disabled v-if="disagreeListstatus.includes(form.status)">已退回上一流程</el-button>
            <el-button type="danger" @click="tuihui" :disabled="disabled"
              v-if="checkListstatus.includes(form.status)">退回</el-button>
          </div>
        </div>
        <!-- 专家审核 -->
        <div v-else-if="isAdmin == 2">
          <div class="kuang">
            <div class="cr-card">
              <!-- 这里显示已经审核的专家意见 -->               
              <div v-if="commentlist.length!=0">
                专家审核意见
                <hr />
                <div class="cgfleft" v-for="(item, index) in commentlist" :key="index">
                  <el-tooltip class="item" effect="light" :content="item.content" placement="bottom">
                    <div class="cgfleftto">
                      {{ item.content }}
                    </div>
                  </el-tooltip>
                </div>
              </div>

              <div v-else>
                <el-form ref="form" :model="form" label-width="80px">
                  <el-form-item label="意见" style="height: 100px;">
                    <el-input type="textarea" v-model="content"></el-input>
                  </el-form-item>

                  <el-button type="primary" @click="checkpass">提交意见</el-button>
                  <el-button @click="tuihui" type="danger" style="float: right;">退回</el-button>
                </el-form>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
    <el-dialog title="意见模板" :visible.sync="dialogVisible" width="50%">
      <el-input type="textarea" :rows="2" placeholder="请输入意见模板" v-model="mbcontent">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMuban">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getBiaozhunDetail,
  enterpriseExamine,
  addmuban,
  mubanlist,
  delmuban,
} from "@api/project";
import axios from "axios";
import { fetchStatusList } from "@utils/tools";
import { formatDate, shenchaType } from "@utils/filters";

let docx = require("docx-preview");
export default {
  filters: {
    shenchaType,
    formatDate,
  },
  data() {
    return {
      // 可审核列表
      checkListstatus: ['2.10', '2.30', '2.50', '3.10', '3.30', '3.50', '4.10', '4.30', '4.50', '5.10', '5.30', '5.50', '6.10', '6.30', '6.50'],
      // 审核未通过的状态列表
      disagreeListstatus: ['2.20', '2.40', '2.60', '3.20', '3.40', '3.60', '4.20', '4.40', '4.60', '5.20', '5.40', '5.60', '6.20', '6.40', '6.60'],
      materImgList: [],
      uploadedImages: [], //审核时的文件列表
      checkedImages: [], //审核完成的文件列表
      reverse: false,
      form: {},
      activities: [{}],
      pass: "通过",
      tui: "退回",
      content: "",
      textarea: "",
      showFileSrc: "",
      showFileType: "",
      nowType: "",
      disabled: false,
      dialogVisible: false,
      mbcontent: "",
      mblist: [],
      userRoleType: [],
      statusform: {},
      newType: 1,
      noworderid: 0, //当前审核的阶段
      isAdmin: 1,
      commentlist: [],
      userid:''
    };
  },
  mounted() {
    this.getIdAndToken();
    this.mubanList();
    // 当前的审核状态


  },
  methods: {
    // 查询专家审核的意见
    checkexpert() {
      var data1 = this.examineslist;
      // console.log(data1);
    },
    // 判断当前状态并给定不同的参数
    statuscheck(statusNumber, addparam) {
      let params = {
        status: '',
        after_status: ''
      };
      if (statusNumber && !isNaN(statusNumber)) {
        params.status = statusNumber + addparam;
        params.after_status = statusNumber + addparam;
      }
      return params;
    },
    //初审通过
    checkpass() {
      // 定义提交参数
      let params = {
        token: this.$store.state.token,
        sid: this.$route.query.id,
        status: "",
        content: this.content,
        type: "",
        examinetype: "",
        after_status: "",
        files: this.uploadedImages,
      };
      this.disabled = true;
      // 当前状态
      const statusNumber = parseInt(this.statusform.number);

      // 添加orderid字段
      this.uploadedImages.forEach((item, index) => {
        item.stageid = statusNumber;
      });

      // 初审状态
      if (this.nowType == "1") {

        this.uploadedImages.map((item) => (item.type = 30));

        let getp = this.statuscheck(statusNumber, '.30');
        params.status = getp.status;
        params.after_status = getp.after_status;

        params.type = "2";
        params.examinetype = "1";
        enterpriseExamine(params)
          .then((res) => {
            this.$message.success("初审通过！");
          }).catch((err) => {
            this.disabled = false;
          });

        // 复审状态
      } else if (this.nowType == "2") {
        this.uploadedImages.map((item) => (item.type = 31));

        let getp = this.statuscheck(statusNumber, '.50');
        params.status = getp.status;
        params.after_status = getp.after_status;
        params.type = "3"
        params.examinetype = "2";
        enterpriseExamine(params)
          .then((res) => {
            this.$message.success("复审通过！");
          }).catch((err) => {
            this.disabled = false;
          });

        // 终审状态
      } else if (this.nowType == "3") {
        this.uploadedImages.map((item) => (item.type = 32));
        let getstatus = this.statuscheck(statusNumber, '.90');
        params.status = getstatus.status;

        if (this.statusform.number.startsWith('2')) { //形式审查的审查意见
          params.after_status = "3.10"; //这里修改成直接进行专家评审，评审完成后再进行提交资料
        } else if (this.statusform.number.startsWith('3')) { //专家初审的审查意见
          params.after_status = "4.05";
        } else if (this.statusform.number.startsWith('4')) {
          params.after_status = "5.00";
        } else if (this.statusform.number.startsWith('5')) {
          params.after_status = "6.00";
        } else if (this.statusform.number.startsWith('6')) {
          params.after_status = "7.00";
        }

        params.type = "1"
        params.examinetype = "3";
        enterpriseExamine(params)
          .then((res) => {
            this.$message.success("终审通过！");
          }).catch((err) => {
            this.disabled = false;
          });

      }

    },
    //初审退回
    tuihui() {

      // 当前状态
      const statusNumber = parseInt(this.statusform.number);

      let params = {
        token: this.$store.state.token,
        sid: this.$route.query.id,
        status: "",
        after_status: "",
        content: this.content,
        type: 1,
        examinetype: 0,
      };
      this.$confirm('确认退回本标准？')
        .then(_ => {
          this.disabled = true;

          if (this.nowType == 1) { //初审退回到用户
            params.type = 1;
            let getp = this.statuscheck(statusNumber, '.20'); //TODO 这里注意下在抽检中增加5.05用户填写资料的状态
            params.status = getp.status;
            params.after_status = getp.after_status;

            enterpriseExamine(params)
              .then((res) => {
                if (this.nowType == 7) {
                  this.$message.success("标准终止，标准信息留档");
                } else {
                  this.$message.success("退回到用户");
                }
              })
              .catch((err) => {
                this.disabled = true;
              });

          } else if (this.nowType == 2) { //复审退回到初审
            params.type = 1;
            let getp = this.statuscheck(statusNumber, '.10');
            params.status = getp.status;
            params.after_status = getp.after_status;

            enterpriseExamine(params)
              .then((res) => {
                this.$message.success("退回到初审");
              })
              .catch((err) => {
                this.disabled = true;
              });

          } else if (this.nowType == 3) { //终审退回到复审
            params.type = 2;
            let getp = this.statuscheck(statusNumber, '.30');
            params.status = getp.status;
            params.after_status = getp.after_status;

            enterpriseExamine(params)
              .then((res) => {
                this.$message.success("退回到复审");
              })
              .catch((err) => {
                this.disabled = true;
              });

          }
        })
        .catch(_ => { });
    },

    zhanshi(item) {
      window.open(item.url);
    },
    //根据审核类型获取对应的审核文件
    activeFile(type) {

      if (type == 1) {

        return this.checkedImages.filter((item) => item.type == 30 && item.stageid == this.noworderid);
      } else if (type == 2) {
        return this.checkedImages.filter((item) => item.type == 31 && item.stageid == this.noworderid);
      } else if (type == 3) {
        return this.checkedImages.filter((item) => item.type == 32 && item.stageid == this.noworderid);
      } else if (type == 7) {
        return this.checkedImages.filter((item) => item.type == 33 && item.stageid == this.noworderid);
      }
    },

    delfiles(index) {
      this.uploadedImages.splice(index, 1);
    },

    delmblist() {
      let mbid = this.mblist[0].id;
      let params = {
        token: this.$store.state.token,
        id: mbid,
      };
      delmuban(params).then((res) => {
        this.$message.success("删除成功");
        this.dialogVisible = false;
        this.getIdAndToken();
        this.mubanList();
      });
    },

    changeMaterImg(index, response, file, fileList) {
      this.uploadedImages.push(response.data);
    },

    changeContent(item) {
      this.content = item.content;
    },

    addMuban() {
      let params = {
        token: this.$store.state.token,
        content: this.mbcontent,
        type: "1",
      };
      addmuban(params).then((res) => {
        this.$message.success("添加成功");
        this.dialogVisible = false;
        this.getIdAndToken();
      });
    },

    mubanList() {
      let params = {
        token: this.$store.state.token,
        page: 1,
        limit: 15,
      };
      mubanlist(params).then((res) => {
        this.mblist = res.data;
      });
    },

    viewFile(src) {
      this.showFileSrc = src;
      if (this.showFileSrc.lastIndexOf(".") != -1) {
        if (
          this.showFileSrc
            .substring(this.showFileSrc.lastIndexOf("."))
            ?.indexOf("doc") != -1
        ) {
          this.showFileType = 1;
          this.$nextTick(function () {
            axios({
              method: "get",
              responseType: "blob", // 设置响应文件格式
              url: src,
            }).then(({ data }) => {
              docx.renderAsync(data, this.$refs.docx_dom); // 获取到biob文件流，进行渲染到页面预览
            });
          });
        } else {
          this.showFileType = 2;
        }
      } else {
        this.showFileType = "";
      }
    },

    goback() {
      this.$router.go(-1);
    },
    //获取id和token
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.isAdmin = this.$store.state.user.type;
      this.userid = this.$store.state.user.id;

      this.getInfo();
      this.checkexpert();
    },

    // 审核内容过滤
    checkDatafilter(listData, filterString) {
      return listData.filter(item => item.status.startsWith(filterString))
    },
    // 获取详情
    async getInfo() {

      try {

        const res = await getBiaozhunDetail({ id: this.id, token: this.token });

        this.formStatus = parseFloat(res.data.status);
        this.noworderid = parseInt(res.data.status); //当前阶段
        var examineslist = res.data.examines;
        // 这里只显示各自专家的数据
        if(this.isAdmin==2){          
          this.commentlist = examineslist.filter(item => item.user_id === this.userid);          
        }

        // 增加对左边提交文件的列表阶段筛选    
        this.materImgList = res.data.files;

        // 下面的图片列表审核的图片列表
        this.checkedImages = res.data.files.filter(
          (item) =>
            item.type == 30 ||
            item.type == 31 ||
            item.type == 32 ||
            item.type == 33
        );

        if (this.materImgList && this.materImgList.length > 0) {
          this.viewFile(this.materImgList[0].url);
        }

        this.statusform = await fetchStatusList(res.data.status, this.page, this.token); // 在组件创建时调用接口获取状态列表
        this.htmlTitle = res.data.proName;
        this.form = res.data;
        this.nowType = res.data.type;
       
        // 这里需要根据不同路由的情况对审核的数据进行筛选

        if (this.statusform.number.startsWith('2')) { //形式审查的审查意见
          this.activities = this.checkDatafilter(res.data.examines, '2');
        } else if (this.statusform.number.startsWith('3')) { //专家初审的审查意见
          this.activities = this.checkDatafilter(res.data.examines, '3');
        } else if (this.statusform.number.startsWith('4')) { //认证前公示
          this.activities = this.checkDatafilter(res.data.examines, '4');
        } else if (this.statusform.number.startsWith('5')) { //抽检验证
          this.activities = this.checkDatafilter(res.data.examines, '5');
          this.activities = this.activities.filter((item) => item.status != '5.05');
        } else if (this.statusform.number.startsWith('6')) { //专家认证
          this.activities = this.checkDatafilter(res.data.examines, '6');
          this.activities = this.activities.filter((item) => item.status != '6.05');
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style scoped>
/deep/.el-timeline-item__node--normal {
  margin-top: 8px;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #ffffff;
}

/deep/.el-textarea__inner {
  /* border: none; */
  min-height: 100px !important;
}

/deep/.el-textarea {
  height: 100px;
}

/deep/.el-timeline {
  padding-left: 10px;
  height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}

.el-timeline::-webkit-scrollbar {
  display: none;
}

.el-tooltip .neirongto:hover {
  color: #3082e2 !important;
}
</style>
<style scoped lang="scss">
.chfiles {
  border-top: 1px solid #d8d8d8;
  margin-top: 10px;
  padding-top: 10px;
}

.listrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.listrownam {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.shehe {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  padding-right: 10px;
  width: 37%;
}

.filebtn {
  margin: 10px 0;
}

.flowrow {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;

  .flowleft {
    font-size: 16px;
    font-weight: 400;
    color: #409eff;
    margin-right: 10px;
    cursor: pointer;
  }

  .flowright {
    color: #409eff;
    margin-top: 2px;
    cursor: pointer;
  }
}

.mbline {
  height: 35px;
  line-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .neirong {
    width: 80%;
    cursor: pointer;
  }

  .neirongto {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .delbtn {
    cursor: pointer;
  }
}

.checktitle {
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  padding-top: 3px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tilelft {
  font-size: 22px;
  // font-weight: bold;
  color: #1a1a1a;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tiecg {
  margin-bottom: 20px;
}

.lefttie {
  border-bottom: 1px solid #dbe9ef;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ltleft {
    border-left: 4px solid #3082e2;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #3082e2;
  }

  .ltright {
    font-size: 14px;
    color: #509bfd;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
}

.leftcg {
  width: 19%;
  background: #ffffff;
}

.centercg {
  width: 55%;
  background: #ffffff;
}

.rightcg {
  width: 24%;
  padding-right: 20px;
}

.linetie {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  margin-top: 15px;
}

.lttime {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.ltmanger {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  width: 80%;
}

.kuang {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.29);
  margin: 10px;
  padding: 10px 15px;
}

.cr-card {
  .checkcont {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    // border-bottom: 1px solid #dbe9ef;
  }
}

.cgfleftto {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.cgfleftto:hover {
  color: #3082e2;
  cursor: pointer;
}

.tgbtn {
  padding: 25px 80px;
}

.leftfile {
  padding: 10px;

  .filelistcg {
    .cgfiles {
      height: 54px;
      background: #f4f4f4;
      line-height: 54px;
      padding: 0 20px;
      margin-bottom: 10px;

      .cgfleft {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        width: 80%;
      }

      .cgfrig {
        font-size: 14px;
        font-weight: 400;
        color: #509bfd;
        cursor: pointer;
      }

      .cgfrig:hover {
        color: #84b5ec;
      }
    }
  }
}

.fileiframe {
  height: 74vh;
  padding: 10px 1%;
  overflow: auto;

  .iframe {
    width: 100%;
    height: 98%;
  }
}
</style>
